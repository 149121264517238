.coin-form-field-outline:not(.mat-form-field-disabled) .mat-mdc-form-field-flex:hover .mdc-notched-outline {
  opacity: 1 !important;
  transition: opacity 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-mdc-form-field:has(.mdc-notched-outline):not(.mat-mdc-paginator-page-size-select) {
  @extend .coin-form-field-outline;
}
.mat-mdc-form-field:not(:has(.mdc-notched-outline)) {
  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--purple700);
  }

  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
  .mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: var(--purple700);
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: white;
  }

  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.coin-form-field-outline {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  &:not(.mat-form-field-disabled) {
    cursor: pointer;
  }

  &.no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mat-mdc-form-field-subscript-wrapper:before {
    content: none;
  }

  .mdc-notched-outline {
    background-color: white !important;
    border-radius: 30px;
    z-index: -1 !important;

    > div:first-child {
      border-radius: 30px 0 0 30px;
      width: 20px !important;
    }

    > div:last-child {
      border-radius: 0 30px 30px 0;
    }
  }

  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-icon-suffix {
    padding: 0 !important;
    border-radius: 30px;
    min-height: 36px;
    display: flex;
    align-items: center;

    .mat-mdc-input-element {
      caret-color: var(--darkgrey900);
    }

    mat-icon {
      padding: 0;
      margin-right: 12px;
    }
  }

  .mdc-notched-outline > .mat-mdc-notch-piece {
    border-color: transparent;
    &.mdc-notched-outline__notch {
      transform: translateX(-12px) !important;
      width: 0 !important;
      padding: 0 !important;
      border: none !important;
    }
  }

  .mdc-notched-outline > .mat-mdc-notch-piece {
    border-width: 0.1rem !important;
  }

  &:not(.mat-form-field-disabled):hover .mdc-notched-outline > .mat-mdc-notch-piece {
    border-color: var(--purple800) !important;
  }

  .mat-mdc-floating-label,
  .mat-mdc-floating-label span,
  .mat-mdc-floating-label mat-label {
    font-size: 0.9rem;
    line-height: 1.3rem;
    font-family: 'Siemens Sans Black' !important;
    color: var(--purple800);
    overflow: visible;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 24px;
  }

  label.mat-mdc-floating-label {
    > mat-label,
    > span {
      width: auto !important;
      height: 20px;
      border-radius: 30px;
      background-color: white;
    }

    > *:first-child {
      padding: 0 8px;
    }

    > span {
      margin-left: -8px;
      padding-right: 8px;
    }
  }

  mat-select {
    .mat-mdc-select-value-text,
    .mat-mdc-select-value-text > span {
      font-family: Siemens Sans;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.02rem;
      font-weight: 400;
      color: var(--darkgrey900);
    }

    .mat-mdc-select-value-text p {
      height: 16px;
    }

    .mat-mdc-select-value > span {
      font-family: Siemens Sans !important;
      font-size: 0.75rem;
      line-height: 1rem;
    }

    &.mat-mdc-select-disabled {
      opacity: 0.9;
    }
  }

  .mat-mdc-form-field-flex {
    .mat-mdc-form-field-infix {
      input,
      textarea {
        border: none !important;
        min-height: 20px;
        font-family: Siemens Sans !important;
        font-size: 0.75rem;
        line-height: 1rem;
      }
      textarea {
        margin: 10px;
      }
    }
  }

  .mat-mdc-form-field-icon-suffix {
    top: 0 !important;

    mat-icon {
      color: var(--purple700);
      font-size: 1rem !important;
      line-height: 1.5rem !important;
    }
  }

  input {
    font-family: Siemens Sans !important;
    font-size: 0.75rem;
    line-height: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 16px;
  }

  &.disabled,
  &.mat-form-field-disabled:not(.coin-form-field-outline--transparent) {
    .mdc-notched-outline {
      background-color: var(--lightgrey200) !important;
    }

    label.mat-mdc-floating-label {
      color: var(--purple700);

      mat-label {
        background-color: var(--lightgrey200);
        border-radius: 20px;
        border-top: 1px solid rgb(209, 216, 224);
      }
    }

    input,
    textarea {
      color: var(--darkgrey900);
    }
  }

  &.mat-focused {
    .mdc-notched-outline > .mat-mdc-notch-piece {
      border-color: var(--purple700);
    }
  }

  &.ng-invalid.ng-touched {
    .mdc-notched-outline > .mat-mdc-notch-piece {
      border-color: var(--red400) !important;
    }
  }

  &--dynamic-height {
    .mat-mdc-form-field-infix {
      height: unset;
    }
  }

  &--shadow {
    .mdc-notched-outline {
      box-shadow: 0px 2px 5px darkgray !important;
    }
  }

  &--shadow-dark {
    .mdc-notched-outline {
      box-shadow: 0px 2px 5px var(--darkgrey700) !important;
    }
  }

  &--grey {
    .mdc-notched-outline {
      background-color: var(--lightgrey200) !important;
    }
  }

  &--preIcon {
    .mat-mdc-form-field-infix {
      padding-left: 30px;
    }
  }

  &--transparent {
    .mdc-notched-outline {
      background-color: transparent !important;
    }
  }

  &--customer-grey {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    &.textarea-container {
      border-width: 1px !important;
    }

    .mat-mdc-text-field-wrapper,
    .coin-form-field-outline {
      padding-bottom: 0 !important;
      margin: 0 !important;
    }
    .mdc-notched-outline {
      background-color: white !important;
    }
    &:not(.mat-form-field-disabled):hover .mdc-notched-outline > .mat-mdc-notch-piece {
      border-color: var(--lightgrey400) !important;
      border-width: 3px !important;
    }
    .mdc-notched-outline > .mat-mdc-notch-piece {
      border-color: var(--lightgrey400) !important;
      border-width: 2px !important;
    }
    .mat-mdc-select-value {
      border-right: 1px solid var(--lightgrey400);
      margin-right: 6px;
      > span {
        font-weight: bold;
      }
    }
  }

  &--svgIcon {
    .mat-mdc-form-field-infix input {
      padding-right: 20px;
    }
  }

  &--date-range {
    input {
      text-overflow: initial;
    }

    span {
      font-size: 0.75rem;
    }

    .mat-datepicker-toggle button {
      border: none !important;
    }

    .mat-mdc-form-field-flex {
      padding-right: 8px;
    }

    .mat-datepicker-toggle-default-icon {
      color: var(--purple800);
    }

    .mat-mdc-form-field-icon-suffix {
      top: 2px !important;
    }

    .mat-date-range-input-separator {
      margin: 0 4px 3px 4px;
    }
  }

  &--date {
    .mat-mdc-text-field-wrapper {
      padding-bottom: 0px;
    }
  }
}

.error-tooltip .mdc-tooltip__surface {
  text-align: left;
  background-color: white !important;
  border: 2px solid var(--red400);
  color: black !important;
}
