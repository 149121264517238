.cdk-drag-preview {
  opacity: 0.5;
  cursor: grab;

  i,
  mat-icon {
    display: none !important;
  }
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-list.cdk-drop-list-dragging .content-row:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.content-row,
.cdk-drag-preview {
  display: grid;
  gap: 0px;

  border-top: 2px solid var(--lightgrey200);

  &.no-border {
    border-top: none;
  }

  coin-v2-dropdown {
    width: 100%;

    .mat-mdc-form-field {
      margin-top: 0 !important;
    }
  }

  .content-column {
    box-sizing: border-box;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 5px;
    position: relative;
    word-wrap: break-word;

    &.overlay {
      background-color: var(--header-table-overlay-content) !important;
      border-left: 1px solid whitec2;

      &--contrast {
        background-color: rgb(213, 237, 237) !important;
      }
    }

    &.excluded {
      opacity: 0.6;
      pointer-events: none;
    }

    // for grouping columns
    &.inactive {
      > * {
        opacity: 0;
        pointer-events: none;
      }
    }

    &.hidden {
      display: none !important;
    }

    &.single-row {
      > p {
        -webkit-line-clamp: 1;
      }
    }

    &.not-ready {
      opacity: 0.7;
    }

    &.more-spacing {
      padding: 8px 10px;
    }

    &:nth-child(2n) {
      background-color: var(--lightgrey100);
    }

    &.force-border {
      border-left: 1px solid var(--lightgrey200);
    }

    &:last-child {
      padding-right: 20px !important; // width of the ngx-simplebar scrollbar
    }

    &.small-padding {
      padding: 5px 5px;
      padding-right: 5px !important;
    }

    &.readonly {
      background-color: var(--lightgrey400);
    }

    &.edit {
      background-color: white;
    }

    &.expand-text {
      p:hover,
      input:hover,
      textarea:hover {
        transition: 200ms;
        white-space: normal;
        display: block;
      }
    }

    &.expanded-text {
      p,
      input,
      textarea {
        white-space: normal;
        -webkit-line-clamp: unset;
      }
    }

    &.flex {
      display: flex;
      justify-content: space-around;

      &--end {
        justify-content: flex-end;
      }
    }

    &.checkbox {
      display: flex;
      justify-content: center;

      mat-icon {
        margin: 0;
        font-size: 20px !important;
        color: var(--red400) !important;
        display: flex;
        align-items: center;
        justify-content: center;

        &.contrast {
          color: var(--green400) !important;
        }
      }
    }

    &.highlight {
      background-color: var(--lightgrey100);
    }

    &.highlight-contrast {
      background-color: white;
    }

    &.centered {
      justify-content: center;
    }

    .floating-icon {
      position: absolute;
      top: 0;
      right: -5px;
    }

    p,
    input,
    textarea {
      text-overflow: clip;
      align-items: center;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      &.center {
        text-align: center;
        width: 100%;
      }
    }

    input,
    textarea {
      font-size: 0.75rem;
      margin-right: 5px;
      border: 0;
      display: inline-block;
      color: black;
      max-height: 60px;
      border-bottom: 1px solid var(--darkgrey500);

      &:focus {
        border-bottom: 2px solid var(--purple700);
      }

      &:disabled {
        color: black;
        background: transparent;
      }
    }

    textarea {
      resize: none;
    }

    input {
      height: 28px;
      text-align: center;
      width: 100%;

      &:disabled {
        border-bottom: none;
      }
    }

    i,
    mat-icon {
      font-size: 18px;
      height: 18px;
      width: 18px;
      margin-right: 5px;
      color: var(--purple700);

      &.big {
        font-size: 24px;
        height: 24px;
        width: 24px;
      }
    }
  }

  &.highlight {
    .content-column {
      animation-name: background-color;
      animation-duration: 1.5s;
      animation-iteration-count: 3;
      animation-direction: alternate-reverse;
      animation-timing-function: ease-in;
    }

    @keyframes background-color {
      to {
        background-color: var(--petrol200) !important;
      }
    }
  }
}

.simple-table {
  min-height: 0;
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;

  .header-row {
    display: grid;
    z-index: 10;

    .no-overflow-x {
      overflow-x: hidden;
    }

    .header-column {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      box-sizing: border-box;
      background: var(--lightgrey100);
      padding: 0 10px;
      min-height: 36px;
      border-top: 1px solid var(--lightgrey400);
      border-bottom: 1px solid var(--lightgrey400);

      &:last-child {
        border-right: 1px solid var(--lightgrey400);
      }

      &.overlay {
        background-color: var(--header-table-overlay);

        p,
        span,
        img,
        mat-icon {
          color: var(--darkgrey900) !important;
        }

        img {
          filter: invert(60%) hue-rotate(315deg) brightness(95%) contrast(101%);
        }
      }

      &.overlay > ::before {
        content: '';
        display: block;
        width: 100%;
        height: 30px;
        left: 0;
        top: -30px;
        position: absolute;
        z-index: -1;
        border-top: 2px solid var(--header-table-overlay-border);
        border-bottom: 2px solid var(--header-table-overlay-border);
        background-color: var(--header-table-overlay-title);
        pointer-events: none;
      }

      &.overlay--start > ::before {
        border-left: 2px solid var(--header-table-overlay-border);
        border-top-left-radius: 20px;
      }

      &.overlay--end > ::before {
        border-right: 2px solid var(--header-table-overlay-border);
        border-top-right-radius: 20px;
      }

      &.overlay--contrast {
        background-color: var(--header-overlay-contrast);

        > div::before {
          background-color: var(--header-overlay-title-contrast);
          border-top: 2px solid var(--header-overlay-border-contrast);
          border-bottom: 2px solid var(--header-overlay-border-contrast);
        }

        &.overlay--start {
          > div::before {
            border-left: 2px solid var(--header-overlay-border-contrast);
            border-top-left-radius: 20px;
          }
        }

        &.overlay--end {
          > div::before {
            border-right: 2px solid var(--header-overlay-border-contrast);
            border-top-right-radius: 20px;
          }
        }
      }

      &.with-controls {
        padding: 0 10px;
        padding-bottom: 5px;
      }

      &.centered {
        text-align: center;
      }

      &.force-border::before,
      & + .header-column::before {
        content: '';
        background-color: white;
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 80%;
        transform: translate(0, -50%);
      }

      & + .header-column::before {
        background-color: var(--lightgrey400);
      }

      p {
        font-family: Siemens Sans Black;
        font-size: 0.65rem;
        line-height: 1rem;
        letter-spacing: 0.02rem;
        font-weight: 400;
        color: var(--darkgrey900);
        align-self: initial;
        text-transform: initial;
      }
    }
  }

  .content-container {
    flex: 1;
    min-height: 0px;
    width: 100%;

    .drop-list {
      .content-row {
        &:not(.drag-disabled) {
          cursor: grab;
        }
      }

      .drag-disabled {
        border-bottom: 2px solid var(--purple800);
        border-top: 2px solid var(--purple800);
        bottom: 76px;
        z-index: 1;
      }
    }
  }

  .content-row:nth-child(odd) .content-column {
    background-color: var(--lightgrey50);
  }

  .content-row:nth-child(even) .content-column {
    background-color: white;
  }

  .content-row:last-child .content-column {
    border-bottom: 1px solid var(--lightgrey200);
  }

  .content-row {
    border-top: none;

    .content-column {
      border-top: 1px solid var(--lightgrey200);
      &:not(:first-child) {
        border-left: 1px solid var(--lightgrey200);
      }
    }
  }

  .no-items {
    color: white;
    margin: 1rem auto;
  }

  &.no-grid {
    .header-row {
      .header-column p {
        overflow: hidden;
        text-align: center;
      }
    }

    .header-row,
    .content-row {
      display: flex;
      width: 100%;
      position: relative;
      transition: width 200ms;
      padding-right: 0;

      .fixed-width-50 {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
      }

      .fixed-width-100 {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
      }

      .fixed-width-150 {
        width: 150px;
        min-width: 150px;
        max-width: 150px;
      }

      .fixed-width-200 {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
      }

      .expand-width {
        flex: 1;
      }

      input {
        width: 100% !important;
        margin-right: 0 !important;
      }

      &.clickable {
        cursor: pointer;

        &:hover {
          .content-column {
            background-color: var(--lightgrey600);
          }
        }
      }
    }
  }
}
