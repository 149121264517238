app-home,
app-cms,
app-incentive-overview,
app-interfaces {
  .square-box:not(.inactive) {
    &::before {
      background: var(--purple800) !important;
    }
    &:hover {
      &::before {
        background: var(--purple600) !important;
      }
    }
  }
}

app-population-table {
  .cr-icon {
    color: var(--purple900) !important;
  }
}

app-master-data,
app-user-mgmt,
app-translations,
app-app-settings {
  .lang-headline {
    background-color: var(--purple900) !important;
  }

  .role-beacon {
    background-color: var(--purple800) !important;
  }
}

app-user-mgmt,
app-translations,
app-app-settings {
  i {
    color: var(--purple900) !important;
  }
}

.sm-header,
.dialog-headline,
app-confirmation-dialog .header,
.page-headline {
  background-image: linear-gradient(to right, var(--darkgrey900), var(--darkgrey900), var(--purple800), var(--darkgrey900), var(--darkgrey900)) !important;
}

.budget-group {
  .with-children {
    background-color: var(--purple900) !important;
  }
}

app-tab-selection {
  .selection-item {
    &.tab-selected {
      background-color: var(--purple800) !important;
    }
  }
}

app-divider {
  .thumb {
    background: var(--purple800) !important;
  }
}

app-simulation-management,
app-incentive-plan-management {
  .header-row {
    background-color: var(--darkgrey900) !important;
  }
}

app-create-season {
  .step {
    &--1 {
      background: var(--purple600) !important;
    }
    &--2 {
      background-image: linear-gradient(to right, var(--purple800), var(--darkgrey900)) !important;
    }
  }
  .remove-step {
    i {
      color: var(--purple800) !important;
    }
  }
}

// button:not(.contrast):not(.btn-white) {
//     background: var(--purple800) !important;
//     color: white !important;
//     &:hover {
//         background: var(--purple900) !important;
//     }
// }

app-simple-table {
  .paginator {
    .mat-mdc-paginator-navigation-previous,
    .mat-mdc-paginator-navigation-next {
      background: transparent !important;
    }
  }
}

.admin-form-field-outline .mdc-notched-outline,
.admin-form-field-outline .mat-mdc-floating-label mat-label {
  color: var(--purple800) !important;
}

app-user-menu {
  .profile-info {
    p {
      &:first-child {
        color: var(--purple900) !important;

        &::after {
          color: var(--purple900) !important;
        }
      }
    }
  }
  ion-avatar {
    background: var(--purple900) !important;
    border: solid 0px var(--purple900) !important;
    .mat-icon {
      color: white !important;
    }
  }
  .menu-entry {
    opacity: 1 !important;
    background-color: var(--purple600) !important;
  }
}

.notifications {
  background: var(--purple800) !important;
}

.header-icons {
  .m-icon {
    &--faq-icon {
      background: var(--purple800) !important;
    }

    &--lang-icon {
      background: var(--purple800) !important;
    }
  }
  .search-icon {
    background: var(--purple800) !important;
    i {
      font-size: 23px !important;
      margin: auto;
    }
  }
}
.mdc-switch__track:after {
  background-image: linear-gradient(to right, var(--purple800), var(--darkgrey900)) !important;
}

.simple-table .content-container .content-row .content-column .edit-button,
.edit-button {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  max-width: 24px !important;
  max-height: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 50%;
  background-color: var(--purple700);
  color: white;
  transition: transform 200ms;
  cursor: pointer;
  user-select: none;
  margin: 0 5px;
  padding: 1px;

  &:hover {
    transform: scale(0.9);
  }

  &--disabled {
    background-color: var(--lightgrey400) !important;
    pointer-events: none;
    color: white !important;
    outline: none !important;

    &:hover {
      transform: none;
    }
  }

  &--contrast {
    background-color: transparent;
    color: black;
  }

  &--delete {
    background-color: var(--red400);
    padding: 4px 2px 4px 3px;
  }

  &--secondary {
    @extend .edit-button;
    color: var(--darkgrey900);
    background-color: var(--lightgrey200);
    outline: 2px solid var(--lightgrey400);
  }
}
