@use './mixins';

.ios app-footer {
  display: none;
}

.ios .te-mgmt-analytics {
  display: none;
}

.cat-items {
  background-color: white;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

app-letters {
  .mat-mdc-tab-labels {
    display: none;
  }
}

.mat-pseudo-checkbox-checked {
  background: var(--purple700) !important;
}

.d1r-dialog-container {
  border-radius: 15px;
  overflow: hidden;
}

.d1r-dialog-container-bo {
  mat-dialog-container {
    border-radius: 15px !important;
    // overflow: visible;
  }
}

mat-tab-group[vertical] {
  flex-direction: row;

  > mat-tab-header {
    flex-direction: column;
    border-bottom: 0px solid transparent;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    margin-top: 40px;

    .mat-mdc-tab-label-container {
      flex-direction: column;

      .mat-mdc-tab-labels {
        flex-direction: column;
      }

      .mdc-tab-indicator--active .mdc-tab-indicator {
        left: initial !important;
        bottom: initial;
        right: 0;
        width: 2px !important;
        height: 45px !important;
      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    flex-direction: column;
    width: 100%;
  }
}
mat-tab-group[horizontal] {
  flex-direction: column;

  mat-tab-header {
    flex-direction: row;
    .mat-mdc-tab-label-container {
      flex-direction: row;

      .mat-mdc-tab-labels {
        flex-direction: row;
      }
    }
  }
}

// .mat-mdc-tab-group {
//   font-family: Siemens Sans !important;
//   font-size: 0.925rem !important;
//   line-height: 1.0rem !important;
// }

.faq-table-item,
.angular-editor-textarea,
#main-letter-create-content:not(coin-c-ecl-template-preview) {
  td,
  div,
  span {
    font-family: 'Siemens Sans', Tahoma, Geneva, sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.02rem;
    font-weight: 400;
    color: var(--darkgrey900);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transition: font-size 0.3s ease-in-out;
  }
  span {
    padding: 0px;
  }

  table:not(.user-block-table):not(.achievements-table):not(.merit-letter-table-element) {
    width: 100%;
    tr th:not(:last-child),
    tr td:not(:last-child) {
      border-right: 1px solid lightgray;
    }
    tr:not(:first-child):not(:last-child) {
      border-bottom: 1px solid lightgray;
    }
    th {
      padding: 5px;
    }
    td,
    th {
      padding: 5px;
      text-align: right;
    }
    tr th:first-child,
    tr td:first-child {
      text-align: left;
      font-weight: bold;
    }
    tr {
      &:first-child {
        background: var(--lightgrey200);
      }
    }
    td:nth-child(even) {
      font-weight: bold;
    }
    td {
      padding: 3px;
    }
  }

  .user-block-table {
    width: 100%;
    tr:first-child {
      background: unset !important;
    }
  }
}

.signature-content {
  display: flex;
  div {
    flex: 1;
    div:first-child {
      margin-top: 50px;
      width: 80%;
      height: 1px;
      background: var(--lightgrey300);
    }
    p {
      font-weight: bold;
      margin-top: 5px;
    }
  }
  div:last-child {
    div:first-child {
      margin-left: auto;
    }
    p {
      text-align: right;
    }
  }
}
coin-equity-expand-box {
  .mat-expansion-indicator::after {
    color: white !important;
  }
}

coin-html-editor,
.expand-box-content,
.app-faq-answer-level {
  .select-font,
  .select-font-size,
  #foregroundColorPicker-,
  #backgroundColorPicker-,
  #insertHorizontalRule-,
  #backgroundColorPicker-editor,
  #foregroundColorPicker-editor,
  #insertVideo-editor,
  #insertHorizontalRule-editor,
  #clearFormatting-editor,
  #indent-editor,
  #toggleEditorMode-,
  #insertVideo-,
  #insertImage-,
  #outdent-editor {
    display: none !important;
  }

  div,
  td {
    font-family: Siemens Sans !important;
    a {
      text-decoration: underline;
      font-weight: bold;
      color: var(--purple700) !important;
    }
    ul {
      list-style-type: disc;
    }
    ul,
    ol {
      padding-left: 20px;
      li span {
        font-family: 'Siemens Sans', Tahoma, Geneva, sans-serif;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: 0.02rem;
        font-weight: 400;
        color: var(--darkgrey900);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: font-size 0.3s ease-in-out;
      }
    }
    table {
      width: 100%;
      td,
      th {
        // border: 1px solid lightblue;
        padding: 5px;
      }
      // tr:nth-child(even) {
      //   background:var(--lightgrey200);
      // }
    }
  }
}

app-preview-faq {
  .mat-mdc-tab-header {
    display: none !important;
  }
}

.preview {
  .mat-mdc-tab-header {
    margin-top: 0px !important;
  }
}

.simple-table-date {
  input {
    font-family: Siemens Sans !important;
    font-size: 12px;
  }
  .mat-mdc-form-field-infix {
    border: 0;
    padding-left: 2px;
  }
  mat-datepicker-toggle {
    position: static;
    bottom: -5px;
    right: 5px;
  }
  .mat-mdc-floating-label {
    margin-left: 10px;
    margin-top: 3px;
  }
}

app-root {
  height: 100%;
  width: 100%;
  position: absolute;
}

body {
  height: 100%;
  max-height: 100%;

  .primary-scrollbar-vertical {
    @include mixins.primary-scrollbar-vertical();
  }

  .contrast-scrollbar-vertical {
    @include mixins.contrast-scrollbar-vertical();
  }

  .primary-scrollbar-horizontal {
    @include mixins.primary-scrollbar-horizontal();
  }

  .gradient-fade {
    .simplebar-content-wrapper {
      mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0)
      ) !important;
      -webkit-mask-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0)
      ) !important;
    }
  }

  mat-dialog-container {
    border-radius: 15px !important;
    padding: 0 !important;

    // if overflow visible is really necessary...
    // overflow: visible !important;
    // .dialog-headline {
    //   border-top-right-radius: 15px;
    //   border-top-left-radius: 15px;
    // }
  }

  input[type='file'],
  input[type='file']::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }

  button:not([disabled]):hover {
    cursor: pointer;
  }

  .interaction-icon-button {
    padding: 5px;
    border-radius: 5px;
    background-color: var(--purple800) !important;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 5px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    border: none !important;

    &:hover {
      background-color: lightgray;
    }
  }

  ion-avatar {
    overflow: hidden;
  }

  .ion-page {
    overflow: visible;
  }

  app-home {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  .custom-hide-down {
    @media only screen and (max-width: 1536px) {
      display: none;
    }
  }

  app-season-overview {
    background: rgb(86, 106, 116);
  }
}

snack-bar-container {
  margin-top: 70px !important;
}

.dashed-underline {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.3);
}

.finished-changeable {
  > span {
    font-family: Siemens Sans Bold;
    font-family: Siemens Sans Bold;
  }
}

app-translation-view {
  mat-tab-body {
    min-height: calc(100vh - 378px);
    max-height: calc(100vh - 378px);
  }
}

.snow {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url('/assets/images/snow1.png'), url('/assets/images/snow2.png'), url('/assets/images/snow3.png');
  z-index: 2;
  -webkit-animation: snow 10s linear infinite;
  -moz-animation: snow 10s linear infinite;
  -ms-animation: snow 10s linear infinite;
  animation: snow 10s linear infinite;
  pointer-events: none;
}

@keyframes snow {
  0% {
    background-position:
      0 0px,
      0 0px,
      0 0;
  }
  50% {
    background-position:
      500px 500px,
      100px 200px,
      -100px 150px;
  }
  100% {
    background-position:
      500px 1000px,
      200px 400px,
      -100px 300px;
  }
}

@-moz-keyframes snow {
  0% {
    background-position:
      0 0px,
      0 0px,
      0 0;
  }
  50% {
    background-position:
      500px 500px,
      100px 200px,
      -100px 150px;
  }
  100% {
    background-position:
      400px 1000px,
      200px 400px,
      100px 300px;
  }
}

@-webkit-keyframes snow {
  0% {
    background-position:
      0 0px,
      0 0px,
      0 0;
  }
  50% {
    background-position:
      500px 500px,
      100px 200px,
      -100px 150px;
  }
  100% {
    background-position:
      500px 1000px,
      200px 400px,
      -100px 300px;
  }
}

@-ms-keyframes snow {
  0% {
    background-position:
      0 0px,
      0 0px,
      0 0;
  }
  50% {
    background-position:
      500px 500px,
      100px 200px,
      -100px 150px;
  }
  100% {
    background-position:
      500px 1000px,
      200px 400px,
      -100px 300px;
  }
}

.square-box {
  position: relative;
  flex: 0 1 160px;
  overflow: hidden;
  margin: 10px;
  display: inline-block;
  transition: all 400ms ease-in-out;

  &:not(.inactive) {
    cursor: pointer;
  }

  .inactive {
    cursor: not-allowed;
  }

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
    background: var(--petrol700);
    transition: all 400ms ease-in-out;
  }

  &.inactive:before {
    background: lightgray !important;
  }

  &:hover:not(.inactive) {
    transform: scale(1.1);
  }

  .box-text {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    transition: all 400ms ease-in-out;
    width: calc(100% - 10px);

    .box-text-small {
      font-size: 70%;
    }
  }
}

.percent-input {
  transition: all 400ms ease-in-out;
}
@media screen and (max-width: 1150px) {
  .percent-input {
    padding-right: 10% !important;
    transition: all 400ms ease-in-out;
  }
}
@media screen and (max-width: 1050px) {
  .percent-input {
    padding-right: 20% !important;
    transition: all 400ms ease-in-out;
  }
}

@media screen and (max-width: 900px) {
  .percent-input {
    padding-right: 30% !important;
    transition: all 400ms ease-in-out;
  }
}

.green-snackbar {
  background: var(--green300);
}

.red-snackbar {
  background: var(--red300);
}

.mat-badge-content {
  background: var(--purple700);
}

.mdc-linear-progress__primary-bar,
.mdc-linear-progress__bar-inner {
  background-color: var(--purple700) !important;
  border-color: var(--purple700) !important;
}

.fadeIn {
  -webkit-animation: fadeIn 2s forwards;
  -moz-animation: fadeIn 2s forwards;
  animation: fadeIn 2s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.slide {
  position: relative;
  transition: all 400ms ease-in-out;
}

.slideMeIn  {
  -webkit-animation: slideIn 1s forwards;
  -moz-animation: slideIn 1s forwards;
  animation: slideIn 1s forwards;
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0);
  }
}

.forecast-amount {
  display: flex;
  background: repeating-linear-gradient(45deg, rgb(220, 220, 220), rgb(220, 220, 220) 5px, rgb(192, 192, 192) 1px, rgb(192, 192, 1922) 6px);
  border-top: 1px solid rgb(55, 78, 88);
  border-bottom: 1px solid rgb(55, 78, 88);
  transition: all 800ms ease-in-out;
  p {
    color: rgb(55, 78, 88);
  }
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;

  border-bottom: 6px solid rgb(55, 78, 88);
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 6px solid rgb(55, 78, 88);
}

.mat-mdc-slide-toggle.mat-primary .mdc-switch__handle::after,
.mat-mdc-slide-toggle.mat-primary.mat-mdc-slide-toggle-checked .mdc-switch__handle::after {
  background-color: white !important;
}

.mdc-switch__track:after {
  background-image: linear-gradient(to right, var(--blue400), var(--petrol700), var(--petrol500)) !important;
}

.dialog-overflow-visible {
  mat-dialog-container,
  .mdc-dialog__surface {
    overflow: visible;
  }
}

.dialog-overflow-hidden {
  mat-dialog-container,
  .mdc-dialog__surface {
    overflow: hidden;
  }
}

.mdc-switch__track {
  height: 22px !important;
  border-radius: 11px !important;
}

.mat-mdc-input-element:not(.bold) {
  font-family: Siemens Sans !important;
}

.siemens-logo-big {
  width: 210px;
  height: 75px;
  background: url('/assets/svgs/siemens_logo_white_big.svg') center no-repeat;
  background-size: 100%;
}

.dashboard-image {
  height: 100%;
  background: url('/assets/images/mac_skyline.png') center no-repeat;
  background-size: cover;
  background-position: center;
}

.admin-form-field-outline:not(.mat-form-field-disabled) .mat-mdc-form-field-flex:hover .mdc-notched-outline {
  opacity: 1 !important;
  transition: opacity 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.admin-form-field-outline {
  width: 100%;
  margin-top: 10px;

  &.no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mdc-notched-outline {
    background: white !important;
    border-radius: 30px;

    > div:first-child {
      border-radius: 30px 0 0 30px;
      width: 30px !important;
    }

    > div:last-child {
      border-radius: 0 30px 30px 0;
    }
  }

  .mat-mdc-form-field-infix {
    padding: 0 !important;
    border-radius: 30px;
    min-height: 36px;
    display: flex;
    align-items: center;

    .mat-mdc-input-element {
      caret-color: var(--darkgrey900);
    }
  }

  .mdc-notched-outline > .mat-mdc-notch-piece {
    border-color: transparent;
    &.mdc-notched-outline__notch {
      width: 0 !important;
      padding: 0 !important;
      border: none !important;
    }
  }

  .mdc-notched-outline > .mat-mdc-notch-piece {
    border-width: 0.1rem !important;
  }

  &:hover .mdc-notched-outline > .mat-mdc-notch-piece {
    border-color: var(--purple700) !important;
  }

  .mat-mdc-floating-label,
  .mat-mdc-floating-label span,
  .mat-mdc-floating-label mat-label {
    font-size: 1rem;
    line-height: 1.1rem;
    font-family: Siemens Sans Black !important;
    color: var(--purple700);
  }

  .mat-mdc-floating-label label {
    margin-top: -5px;
    width: auto !important;
    border-radius: 30px;
    height: 20px;

    > mat-label,
    > span {
      background-color: white;
    }

    > *:first-child {
      padding: 0 8px;
    }

    > span {
      margin-left: -8px;
      padding-right: 8px;
    }
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }

  mat-select {
    .mat-mdc-select-value-text,
    .mat-mdc-select-value-text > span {
      font-family: Siemens Sans;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 0.02rem;
      font-weight: 400;
      color: var(--darkgrey900);
    }

    .mat-mdc-select-value-text p {
      height: 18px;
    }

    .mat-mdc-select-value > span {
      font-family: Siemens Sans !important;
      font-size: 0.75rem;
      line-height: 1rem;
    }

    &.mat-mdc-select-disabled {
      opacity: 0.9;
    }
  }

  .mat-mdc-form-field-flex {
    .mat-mdc-form-field-infix {
      textarea {
        border: none !important;
        min-height: 20px;
        font-family: Siemens Sans !important;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
  }

  .mat-mdc-form-field-icon-suffix {
    top: 0 !important;

    mat-icon {
      color: var(--purple700);
      font-size: 1rem !important;
    }
  }

  input {
    font-family: Siemens Sans !important;
    font-size: 0.75rem;
    line-height: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 16px;
  }

  .text-to-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.disabled,
  &.mat-form-field-disabled {
    .mdc-notched-outline {
      background-color: var(--lightgrey200) !important;
    }

    .mat-mdc-floating-label {
      label {
        color: var(--purple700);
        padding-top: 8px;
        margin-top: -8px;

        mat-label {
          background-color: var(--lightgrey200);
          border-radius: 20px;
          border-top: 1px solid rgb(209, 216, 224);
        }
      }
    }

    input,
    textarea {
      color: var(--darkgrey900);
    }
  }

  &.mat-focused {
    .mdc-notched-outline > .mat-mdc-notch-piece {
      border-color: var(--purple700);
    }
  }

  &.ng-invalid.ng-touched {
    .mdc-notched-outline > .mat-mdc-notch-piece {
      border-color: var(--red400) !important;
    }
  }

  &--dropdown {
    .mat-mdc-form-field-infix {
      padding: 0em 6px 0em 6px;
    }
  }

  &--dynamic-height {
    .mat-mdc-form-field-infix {
      height: unset;
    }
  }

  &--shadow {
    .mdc-notched-outline {
      box-shadow: 0px 2px 5px darkgray !important;
    }
  }

  &--shadow-dark {
    .mdc-notched-outline {
      box-shadow: 0px 2px 5px var(--darkgrey700) !important;
    }
  }

  &--grey {
    .mdc-notched-outline {
      background-color: var(--lightgrey200) !important;
    }
  }

  &--preIcon {
    .mat-mdc-form-field-infix {
      padding-left: 30px;
    }
  }

  &--svgIcon {
    .mat-mdc-form-field-infix input {
      padding-right: 20px;
    }
  }
}

.admin-form-field {
  margin-top: 10px;

  .mat-mdc-floating-label,
  .mat-mdc-floating-label > span {
    font-family: Siemens Sans Black !important;
    font-size: 0.75rem !important;
    color: rgb(177, 185, 197) !important;
  }

  &.mat-mdc-form-field {
    width: 100%;

    &.ng-invalid.ng-touched .mat-mdc-form-field-infix {
      border: 1px solid var(--red400);
    }
  }

  input {
    font-family: Siemens Sans Black !important;
    font-size: 0.75em;
    line-height: 1em;
    margin-top: 2px;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }

  .mat-mdc-form-field-infix {
    background-color: white;
    border-top: 0px;
    padding: 8px 10px;
    border-radius: 15px;
    height: 34px;

    .mat-mdc-input-element {
      caret-color: var(--darkgrey900);
    }
  }

  .mat-mdc-form-field-empty {
    padding-left: 10px;
  }

  .mat-mdc-select-value-text,
  .mat-mdc-select-value-text > span {
    font-family: Siemens Sans;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.02rem;
    font-weight: 400;
    color: var(--darkgrey900);
  }

  .mat-mdc-form-field-flex {
    .mat-mdc-form-field-infix {
      textarea {
        border: none !important;
        min-height: 22px;
      }
    }
  }

  &--dynamic-height {
    .mat-mdc-form-field-infix {
      height: unset;
    }
  }

  &--shadow {
    .mat-mdc-form-field-infix {
      box-shadow: 0px 2px 5px darkgray !important;
    }
  }

  &--shadow-dark {
    .mat-mdc-form-field-infix {
      box-shadow: 0px 2px 5px var(--darkgrey700) !important;
    }
  }

  &--grey {
    .mat-mdc-form-field-infix {
      background-color: var(--lightgrey200) !important;
    }
  }
  &--preIcon {
    .mat-mdc-form-field-infix {
      padding-left: 30px;
    }
  }

  &--afterIcon {
    .mat-mdc-form-field-infix {
      padding-right: 30px;
    }
  }
}

.modified-mat-chip-list {
  width: 100%;

  &.mat-mdc-chip-grid {
    .mat-mdc-chip {
      margin: 1px;
    }

    .mat-mdc-standard-chip {
      height: 20px;
      background-color: var(--purple700);
      color: white;
      button,
      span {
        border: none !important;
        color: inherit;
      }
    }

    .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove {
      color: white;
    }
  }

  input {
    font-family: Siemens Sans !important;
    font-size: 0.75em !important;
    line-height: 1em !important;
    margin: 4px 0 !important;
    padding-left: 5px;
  }
}

.no-tab-header {
  > .mat-mdc-tab-header {
    display: none !important;
  }
}

.full-tab-height {
  .mat-mdc-tab-body-wrapper {
    height: 100% !important;
  }
}

.mdc-list-item__primary-text {
  font-family: Siemens Sans !important;
}

coin-input {
  position: relative !important;
}

.content {
  .mat-row {
    transition: transform 200ms linear;
    // box-shadow: 0px 0px 0px transparent;
  }
}

.content {
  .mat-column-expandedDetail {
    transform: scale(1.02);
  }
}

.admin-btn {
  border-radius: 17px;
  height: 34px;
  display: inline-block;
  padding: 0;
  color: var(--darkgrey900);

  &:hover {
    background-color: var(--darkgrey100);
  }

  &:disabled {
    opacity: 0.6;

    &:hover {
      background-color: white;
    }
  }

  &--normal {
    margin-right: 10px;
    min-width: 90px;
    text-align: left;
    padding: 0px 10px;
    font-size: 12px;
    vertical-align: bottom;
    span {
      float: right;
      font-size: 17px;
    }
  }

  &--contrast {
    background-color: var(--darkgrey200);
    margin-right: 10px;
    min-width: 90px;
    text-align: left;
    padding: 0px 10px;
    font-size: 12px;
    vertical-align: bottom;
    span {
      float: right;
      font-size: 17px;
    }

    &:hover {
      background-color: gray;
    }
  }

  &--remove {
    margin-top: 10px;
    border-radius: 50%;
    width: 34px;
    display: inline-block;
    padding: 0;
    span {
      line-height: 32px;
      font-size: 17px;
    }
  }

  &--notTop {
    margin-top: 0px;
  }
}

.top-frame {
  .selection-part {
    min-width: 150px !important;
    p {
      padding: 10px 5px !important;
    }
  }
}

.budget-calculation {
  .selected-parameter .percentage,
  .freeze-date,
  .cluster-settings .top-letter {
    .mat-mdc-text-field-wrapper {
      margin-bottom: -1.25em;
    }
    .mat-mdc-form-field-infix {
      padding: 0px;
      border: none;
      width: unset;
    }
  }

  .selected-parameter .percentage {
    .mat-mdc-input-element {
      width: 100%;
      min-width: 30px;
    }
  }
}

.ngx-charts text {
  fill: var(--darkgrey600);
  font-family: Siemens Sans Black;
}

.chart-legend {
  .legend-item {
    color: var(--darkgrey600);
  }
}

.transparent-dialog-panel {
  > mat-dialog-container {
    box-shadow: none;
    background: transparent;
  }
}

.create-topnode {
  .mat-mdc-select {
    span {
      font-size: 1em;
    }
  }
}

app-rule-engine,
app-rule-engine-v2 {
  coin-v2-dropdown,
  coin-input {
    max-width: 35%;
  }
  .mat-mdc-form-field-infix {
    min-width: 120px;
    width: auto;
    margin-top: -3px !important;
    height: 42px !important;
  }
}
.translations-content {
  .mdc-tab__content {
    font-family: Siemens Sans black;
    font-size: 1.25rem;
  }
}

.mdc-checkbox {
  height: 20px !important;
  width: 20px !important;
}

mat-checkbox:not(.mat-mdc-checkbox-disabled) {
  &.mat-mdc-checkbox-ripple .mat-ripple-element,
  &.mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background,
  &.indeterminate .mdc-checkbox__background {
    background-color: var(--purple800) !important;
    border-color: var(--purple800) !important;
    .mdc-checkbox__checkmark-path {
      color: white !important;
    }
  }
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background-color: var(--purple800) !important;
}

mat-checkbox {
  .mdc-form-field > label {
    font-size: 0.75rem;
    font-family: Siemens Sans Black;
    color: var(--darkgrey900);
  }
}

// ng-select
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: var(--purple800) !important;
  border-radius: 20px !important;
  padding: 3px 2px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  color: white;
  border-radius: 20px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: none !important;
  margin-left: 2px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  background-color: white;
  color: var(--darkgrey900);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  display: flex;
  flex-direction: row-reverse;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color: var(--lightgrey200);
}

// pdf export

.hide-scrollbar {
  overflow: hidden;
}

.header-img-hps {
  width: 40px !important;
}

.close-button {
  z-index: 9999;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  background: white;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transform: translate(50%, -50%);
  -webkit-user-select: none;
  user-select: none;

  mat-icon {
    color: var(--purple700);
  }
}

coin-gmm-text-block,
coin-gmm-quote,
coin-news-headline,
coin-gmm-text-image-link {
  a {
    text-decoration: underline;
    font-weight: bold;
    color: var(--purple500) !important;
    font-size: inherit;
  }
}

.mat-mdc-tab-group.mat-primary .mdc-tab-indicator--active {
  .mdc-tab__text-label {
    color: var(--purple700);
  }
  .mdc-tab-indicator {
    background-color: var(--purple700);
  }
  .mdc-tab-indicator__content--underline {
    border-color: var(--purple700);
  }
}

.cp-slide-toggle {
  .mdc-switch__handle {
    height: 18px;
    width: 18px;
    &::after {
      background: white !important;
    }
  }
}

.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle {
  border-color: var(--purple800) !important;
}

.mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked .mdc-radio__outer-circle {
  border-color: var(--purple800) !important;
}

.box {
  border-radius: 20px;
  margin: 10px 0;
  position: relative;
  padding: 12px 0px 3px;

  &.no-padding {
    padding: 0;
  }

  > .head {
    position: absolute;
    background: var(--purple800);
    transform: translateX(-10px);
    color: white;
    border-radius: 20px;
    position: absolute;
    top: -10px;
    padding: 3px 10px;
    z-index: 1;
  }

  .group {
    display: flex;
    flex-wrap: wrap;

    padding: 14px 20px 8px;

    &.grey {
      background-color: var(--lightgrey100);
    }

    &--manager {
      flex: 1;
    }

    .with-seperator {
      border-right: 2px solid var(--lightgrey200);
      flex: 0 1 calc(50% - 12px);
      margin-right: 10px;
    }

    > * {
      margin-bottom: 10px;
    }

    > div {
      flex: 0 1 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      min-width: 0;

      > *:not(textarea) {
        margin-right: 20px;
        flex: 1;
      }

      .calendar-icon {
        $size: 22px;
        width: $size;
        height: $size;
        font-size: $size;
      }

      &.wrap-element {
        flex-basis: 100%;
        margin: 0px;

        &.spacing {
          margin-bottom: 10px;
        }
      }

      &.full-width {
        flex: 0 1 100%;

        .edit {
          flex: 2;
        }

        .calendar-icon {
          margin-right: 8px !important;
        }
      }

      .edit {
        display: flex;
        align-items: center;
        // This padding and margin makes the edit inputs same width as normal inputs in the flex-grid
        padding: 0px 5px;
        margin: 0px 10px 0px -20px;

        &.no-overflow {
          overflow-x: hidden;
        }

        &.incentive-amount-edit {
          flex: 2;

          .incentive-amount-currency {
            flex: 1;
          }

          .incentive-amount {
            flex: 3;
            text-align: right;
          }

          .edit-button {
            margin-right: 0px;
          }
        }

        > *:first-child {
          margin-right: 5px;
        }

        .incentive-payment-type {
          margin-right: 0px;
        }
      }

      .date-info {
        display: flex;
        // justify-content: space-between;
        height: 100%;
        align-items: center;

        .spacer {
          flex: 1;

          &.double {
            flex: 2;
          }
        }

        span {
          margin-right: 4px;
          color: var(--darkgrey500);
        }

        .mat-icon {
          color: var(--darkgrey500);
        }
        .split {
          transform: rotate(90deg);
        }

        > *:last-child {
          margin-right: -10px;
        }

        &.measurement-date {
          flex: 2;

          > *:last-child {
            margin-right: 0px;
          }
        }
      }

      img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 22px;
        border-radius: 5px;
        object-fit: cover;
      }
    }
  }
}
