/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 */

@use 'simplebar/dist/simplebar.css';

/* Structured Packages */
@use './styles/_global/normalize';
@use './styles/_global/variables';
@use './styles/_global/materialicons';
@use './styles/_global/typography';
@use './styles/_global/base';
@use './styles/_global/matslider';
@use './styles/layouts/lay-wrapper';
@use './styles/atoms/a-text';
@use './styles/components/simple-table';
@use './styles//components/ripple.scss';
@use './styles//components/reassignments.scss';

/* To add more visual modifications use this SCSS below */
@use './styles/_global/additionalmods';
@use './styles/_global/cms';

/* Add Global Organizational Stylings here */
@use './styles/_organizational-packages/siemens-energy';
@use 'input-base';

/* Lib Styles */
@use 'ngx-toastr/toastr';
@use '@ng-select/ng-select/themes/default.theme.css';
@use 'quill/dist/quill.core.css' as quill-core;
@use 'quill/dist/quill.bubble.css' as quill-bubble;
@use 'quill/dist/quill.snow.css' as quill-snow;

/* Handsontable default styles */
@use 'coin-handsontable';
