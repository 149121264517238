@use 'sass:map';

$desktop-xxs-width: 750px;
$desktop-xs-width: 940px;
$desktop-s-width: 1024px;
$desktop-m-width: 1366px;
$desktop-l-width: 1920px;
$desktop-xl-width: 2560px;

$breakpoints: (
  tablet: 'only screen and (min-width: #{0})',
  // only width:
  desktop-xxs-width: 'print, only screen and (max-width: #{$desktop-xxs-width})',
  desktop-xs-width: 'print, only screen and (max-width: #{$desktop-xs-width})',
  desktop-s-width: 'print, only screen and (max-width: #{$desktop-s-width})',
  desktop-m-width: 'print, only screen and (max-width: #{$desktop-m-width})',
  desktop-l-width: 'print, only screen and (max-width: #{$desktop-l-width})',
  desktop-xl-width: 'print, only screen and (max-width: #{$desktop-xl-width})',
  // width and height:
  desktop-xxs: 'print, only screen and (max-width: #{$desktop-xxs-width}) and (max-height: #{550px})',
  desktop-xs: 'print, only screen and (max-width: #{$desktop-xs-width}) and (max-height: #{600px})',
  desktop-s: 'print, only screen and (max-width: #{$desktop-s-width}) and (max-height: #{720px})',
  desktop-m: 'print, only screen and (max-width: #{$desktop-m-width}) and (max-height: #{768px})',
  desktop-l: 'print, only screen and (max-width: #{$desktop-l-width}) and (max-height: #{1080px})',
  desktop-xl: 'print, only screen and (max-width: #{$desktop-xl-width}) and (max-height: #{1440px})',
  print: 'print'
);

@mixin coin-linear-background {
  background: linear-gradient(to right, var(--darkgrey900), var(--purple900), var(--purple700), var(--purple900), var(--darkgrey900));
}

@mixin coin-linear-grey-background {
  background: linear-gradient(to bottom, var(--darkgrey500), var(--darkgrey700));
}

@mixin line-clamp($lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

// mixin media query
@mixin mq($type) {
  @if map-has-key($breakpoints, $type) {
    @media #{map.get($breakpoints, $type)} {
      @content;
    }
  } @else {
    @warn "Unknown breakpoint #{$type}!";
  }
}
