@use 'coin-theme';
@use './siemens-energy';
@use './close-dialog-button';
@use './buttons/buttons';
@use './toast.scss';
@use './tooltip.scss';
@use './icon.scss';
@use './mixins';
@use './coin-typography.scss';
@use './modules/gbr.scss';
@use './components/chip-info.scss';
@use './components/ripple.scss';
@use './components/coin-stepper.scss';
@use './components/mat-chip.scss';
@use './components/mat-dialog.scss';
@use './components/mat-input.scss';
@use './components/mat-select.scss';
@use './components/mat-radio-button.scss';
@use './components/mat-slide-toggle.scss';
@use './components/mat-menu.scss';
@use './components/mat-checkbox.scss';
@use './components/mat-card.scss';
@use './components/update-notifier.scss';

* {
  user-select: text;
}
// editor fix for the above rule
quill-editor * {
  user-select: auto;
}

.hover {
  transition: transform 200ms;

  &:hover {
    transform: scale(0.9);
  }
}

[hidden] {
  display: none !important;
}
